import React from "react";
import {Link, useLocation} from "react-router-dom";
import {HomeIcon, CodeBracketIcon, CpuChipIcon, ChevronLeftIcon} from "@heroicons/react/24/solid";
import useLocalStorage from "use-local-storage";

export const NavigationDrawer = () => {
    const routes = [
        {
            path: '/',
            label: 'Home',
            exact: true,
            icon: <HomeIcon className="w-4"/>
        },
        {
            path: '/admin',
            label: 'Admin',
            exact: true,
            icon: <CodeBracketIcon className="w-4"/>
        },
        {
            path: '/bot',
            label: 'Bot',
            exact: false,
            icon: <CpuChipIcon className="w-4"/>
        }
    ]
    const location = useLocation();
    const [open, setOpen] = useLocalStorage('navigation-drawer-open', true)
    return (
        <div
            className={`bg-base-100 flex-shrink-0 h-full relative py-16 ${!open ? 'w-24' : 'w-64'} transition-all duration-500`}>
            <button className="btn btn-square rounded-full btn-ghost absolute top-0 right-0 mt-2 mr-6"
                    onClick={() => setOpen(!open)}>
                <ChevronLeftIcon className={`w-6 ${open ? '' : 'rotate-180'} transition-all duration-500`}/>
            </button>
            <div className={`text-center prose ${!open ? 'hidden' : ''}`}>
                <div className='bg-secondary opacity-10 p-4 my-4 aspect-video rounded w-1/2 m-auto'>

                </div>
                <div className="avatar placeholder">
                    <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                        <span className="text-xl">LR</span>
                    </div>
                </div>
                <h2 className='mt-2'>
                    Laura Roussel
                </h2>
                <button className='btn btn-sm rounded-full'>
                    Modifier le profil
                </button>
            </div>
            <nav className="mt-10 px-6 ">
                {
                    routes?.map((route, index) => (
                        <Link to={route.path} key={index}
                              className={`btn w-full rounded-full mb-2  ${location.pathname === route.path ? 'btn-primary' : 'btn-ghost'}`}
                        >
                            {route.icon}
                            {open && <span>{route.label}</span>}
                        </Link>))
                }
            </nav>
        </div>
    )
}