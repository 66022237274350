import React, {useContext, useEffect} from "react";
import {ConversationContext, RecordingContext} from "../pages/bot";
import {MicrophoneIcon} from "@heroicons/react/24/outline";
import {StopIcon, PauseIcon, ArrowRightIcon} from "@heroicons/react/24/solid";
import {useVoiceVisualizer, VoiceVisualizer} from "react-voice-visualizer";
import Webcam from "react-webcam";


export const ClientScreen = () => {
    const {
        currentConversation,
        updateConversation,
        setCurrentConversation,
        currentStep,
        currentUserMessage,
        currentAssistantMessage,
        lastAssistantMessage,
        startConversation,
        sendMsg
    } = useContext(ConversationContext);
    const recorderControls = useVoiceVisualizer();
    const {
        startRecording: startRecording2,
        stopRecording: stopRecording2,
        pauseRecording: pauseRecording2,
        resumeRecording: resumeRecording2,
        clearRecording: clearRecording2,
        recordingTime,
        recordedBlob,
        error,
        audioRef,
    } = recorderControls;
    // Get the recorded audio blob
    useEffect(() => {
        if (!recordedBlob) return;
    }, [recordedBlob, error]);

    // Get the error when it occurs
    useEffect(() => {
        if (!error) return;
    }, [error]);


    const {
        startRecording,
        stopRecording,
        pauseRecording,
        recording,
        speaking,
        clearRecording,
        transcribing,
        transcript
    } = useContext(RecordingContext);


    const millisecondsToTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
    const [isConversationStarting, setIsConversationStarting] = React.useState(false);

    const [fullScreen, setFullScreen] = React.useState(true);
    useEffect(() => {
        if (!currentAssistantMessage) {
            setTimeout(() => {
                setFullScreen(false)
            }, 2000)
        }

    }, [currentAssistantMessage])


    return (
        <div>
            <div
                className='bg-black shadow-xl w-full aspect-video max-h-[60vh] border-b-black rounded-xl relative overflow-hidden'>
                <h2 className='text-white text-sm shadow  absolute bg-gray-800 z-10 p-2 rounded bottom-4 left-4'>
                    {/* recording time is milliseconds and converted to time looking*/}
                    {recordingTime ? millisecondsToTime(recordingTime) : '00:00'}
                </h2>
                <Webcam width='25%'
                        className='absolute  rounded-xl  top-4 transform  right-4 z-[100]'
                />
                <div
                    className={`bg-base-200 rounded aspect-video h-[25%] absolute left-0 top-0 w-full h-full overflow-hidden transition-all duration-[2000ms]`}>
                    <img
                        src='https://i.ibb.co/D8rcx6V/260a43ee-7bdc-4479-9249-62cab9c77451.webp'/>
                    {/*{!fullScreen &&*/}
                    {/*    <div className='prose text-center'>*/}
                    {/*        <h3 className='absolute bottom-0 bg-opacity-50 bg-black text-white p-6'>*/}
                    {/*            {currentAssistantMessage || lastAssistantMessage}*/}
                    {/*        </h3>*/}
                    {/*    </div>}*/}

                </div>
                <div className="lg:mt-4 flex flex-row items-center justify-center gap-2 absolute p-2">
                    {recording &&
                        <div className="btn btn-xs btn-primary rounded-full">
                            <span className="loading loading-ring loading-xs"></span>
                            Ca enregistre!
                        </div>
                    }
                    {transcribing &&
                        <div className="btn btn-xs btn-accent rounded-full">
                            <span className="loading loading-ring loading-xs"></span>
                            On transcrit!
                        </div>}
                    {speaking &&
                        <div className="btn btn-xs btn-secondary rounded-full">
                            <span className="loading loading-ring loading-xs"></span>
                            On t'entend!
                        </div>}
                </div>
                <div
                    className='absolute bottom-0 py-4  w-full gap-4 bg-black bg-opacity-5  border-t border-black border-opacity-10'>
                    <div
                        className='flex items-center justify-center gap-4 '>
                        {
                            !recording && (
                                <button className='btn btn-lg btn-circle btn-primary border-2 border-gray-300'
                                        disabled={recording || currentStep === 0}
                                        onClick={() => {
                                            startRecording();
                                            startRecording2();
                                        }}
                                >
                                    <MicrophoneIcon className='h-6 text-inherit'/>
                                </button>
                            )
                        }

                        {
                            recording &&
                            (
                                <>
                                    <button className='btn btn-error btn-lg btn-circle border-2 border-gray-300 '
                                            disabled={!recording}
                                            onClick={() => {
                                                stopRecording2();
                                                stopRecording();
                                            }}
                                    >
                                        <StopIcon className='h-6 text-white'/>
                                    </button>
                                </>
                            )
                        }
                        <button className='btn btn-md rounded-full absolute right-4'
                                disabled={!currentUserMessage || recording || transcribing}
                                onClick={sendMsg}>
                            Question suivante
                            <ArrowRightIcon className='h-4'/>
                        </button>

                    </div>
                </div>
            </div>
            {/*<button className='btn btn-primary my-4' onClick={() => {*/}
            {/*    setFullScreen(!fullScreen)*/}
            {/*}}>*/}
            {/*    full screen*/}
            {/*</button>*/}
            <div className='prose my-5 w-full max-w-none'>
                {currentStep === 0 && !currentAssistantMessage ?
                    <button className='btn btn-primary'
                            disabled={isConversationStarting}
                            onClick={() => {
                                startConversation()
                                setIsConversationStarting(true)
                            }}>
                        {isConversationStarting ? <span className="loading loading-ring loading-xs"></span>
                            : ''}
                        Commencer Entretien
                    </button> : <>
                        <h1 className='mb-2'>Etape {currentStep}</h1>
                        <h4>La question</h4>
                        <p className='mt-0'>
                            {currentAssistantMessage ? currentAssistantMessage : lastAssistantMessage}
                        </p>
                        <h4>Votre réponse</h4>
                        {currentUserMessage && transcript.text ? <p>{currentUserMessage}</p> : <p>...</p>}
                    </>}

            </div>
        </div>
    )
}