import React from "react";
import {useForm} from "react-hook-form";

const CreateConversationForm = () => {
    const {register, handleSubmit} = useForm();

    const onSubmit = (data) => {
        fetch("/api/conversation/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(response => response.text())  // Change this to response.json() if API returns JSON
            .then((id) => {
                window.location.href = `/bot?id=${id}`;
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="prose">
            <h2 className="my-2">Créer une nouvelle conversation</h2>
            <div className="flex">
                <input
                    {...register("title")}
                    placeholder="Title"
                    className="input input-bordered flex-grow input-sm"
                />
                <button type="submit" className="btn btn-primary ml-2 btn-sm">
                    Créer
                </button>
            </div>
        </form>
    );
};

export default CreateConversationForm;
