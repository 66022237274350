import React from 'react';
import {BrowserRouter as Router, useLocation, Routes, Route, Link} from 'react-router-dom';
import 'daisyui/dist/full.css';
import {Home} from "../pages/home";
import {Admin} from "../pages/admin";
import {Bot} from "../pages/bot";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/admin" element={<Admin/>}/>
                <Route exact path="/bot" element={<Bot/>}/>
            </Routes>
        </Router>);
};

export default App;