import React, {forwardRef} from "react";

export const Select = forwardRef(({
                                      label,
                                      name,
                                      placeholder,
                                      options,
                                      onChange,
                                      ...props
                                  }, ref) => {
    return (
        <div className="form-control w-full">
            {label && (
                <label className="label">
                    <span className="label-text">{label}</span>
                </label>
            )}
            <select
                className="select select-bordered w-full select-sm"
                name={name}
                onChange={onChange}
                ref={ref}
                {...props}
            >
                {/* <option disabled >
          {placeholder}
        </option> */}
                {options?.map(({label, value}) => {
                    return (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
});
