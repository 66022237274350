import React, {useContext} from "react";
import {ConversationContext} from "../pages/bot";
import {CpuChipIcon} from "@heroicons/react/24/outline";


export const AnalysisTab = () => {
    const {currentConversation, updateConversation, setCurrentConversation} = useContext(ConversationContext);

    return (
        <div className='overflow-auto'>
            <div className='prose m-auto'>
                {
                    currentConversation?.steps.map((step, index) => {
                        const answer = currentConversation?.messages[2 * index + 1]
                        const question = currentConversation?.messages[2 * index]
                        return (
                            <div className='bg-base-100 border-accent shadow-2xl rounded  p-4 my-4 w-full'>
                                <h4>
                                    Partie {index + 1}
                                </h4>
                                <p className='text-sm italic'>
                                    {question?.content}
                                </p>
                                <p className='text-sm'>
                                    {answer?.content}
                                </p>
                                <button className='btn btn-primary '>
                                    <CpuChipIcon className='h-4'/>
                                    Analyser réponse
                                </button>
                            </div>)
                    })
                }

            </div>
        </div>
    )
}