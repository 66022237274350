import Toggle from "./data-input/toggle";
import TextInput from "./data-input/text-input";
import Textarea from "./data-input/textarea";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useContext} from "react";
import {ConversationContext} from "../pages/bot";
import {Select} from "./data-input/select";
import {MinusCircleIcon, PlusIcon} from "@heroicons/react/24/outline";


export const ConfigurationForm = () => {
    const {currentConversation, updateConversation, setCurrentConversation} = useContext(ConversationContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const {register, handleSubmit, reset, setValue} = useForm();
    const [stepsCount, setStepsCount] = useState(currentConversation?.steps.length || 1);
    useEffect(() => {
        reset({
            title: currentConversation?.title,
            is_gpt4: currentConversation?.is_gpt4,
            entreprise: currentConversation?.entreprise,
            role: currentConversation?.role,
            system_prompt: currentConversation?.system_prompt,
            voice_id: currentConversation?.voice_id,
            first_name: currentConversation?.first_name,
            steps: currentConversation?.steps
        });
        setStepsCount(currentConversation?.steps.length || 1)
    }, [currentConversation]);

    const handleUpdateConversation = (data) => {
        setIsSubmitting(true);
        updateConversation(data).then((d) => {
            setIsSubmitting(false);
            setIsSuccess(true);
            setCurrentConversation(
                {
                    ...currentConversation,
                    ...data
                }
            )
            setTimeout(() => {
                setIsSuccess(false);
            }, 3000);
        })
    }


    return (<>
        {isSuccess && (<div className="toast toast-bottom toast-start z-10 bottom-10">
            <div className="alert alert-success">
                <span>Conversation màj.</span>
            </div>
        </div>)}

        <form onSubmit={handleSubmit(handleUpdateConversation)} className="w-full">
            <div className='flex justify-between items-center'>
                <h2>Configuration</h2>
                <button className="btn btn-sm btn-primary mt-4" disabled={!currentConversation && isSubmitting}>
                    Mettre à jour
                </button>
            </div>
            <div className='flex justify-between'>
                <h4>Configuration Candidat:</h4>
            </div>

            <div className="flex gap-3">
                <TextInput
                    label="Candidat"
                    placeholder="Prénom du candidat"
                    defaultValue={currentConversation?.first_name}
                    {...register("first_name")}
                    onBlur={handleSubmit(handleUpdateConversation)}
                />
                <TextInput
                    label="Entreprise"
                    placeholder="Choisissez une entreprise"
                    defaultValue={currentConversation?.entreprise}
                    {...register("entreprise")}
                    onBlur={handleSubmit(handleUpdateConversation)}

                />
                <TextInput
                    label="Role"
                    placeholder="Choisissez un role"
                    defaultValue={currentConversation?.role}
                    {...register("role")}
                    onBlur={handleSubmit(handleUpdateConversation)}

                />

            </div>
            <h4>Configuration Admin:</h4>
            <Toggle
                label="GPT-4"
                name="isGpt4"
                defaultChecked={currentConversation?.is_gpt4}
                {...register("is_gpt4")}
                onBlur={handleSubmit(handleUpdateConversation)}

            />
            <div className="flex gap-3">
                <TextInput
                    label="Titre"
                    placeholder="Choisissez un titre"
                    {...register("title")}
                    defaultValue={currentConversation?.title}
                    onBlur={handleSubmit(handleUpdateConversation)}

                />
                <Select label="Voix" {...register("voice_id")} options={VOICES_OPTIONS}
                        onBlur={handleSubmit(handleUpdateConversation)}
                />
            </div>
            <Textarea
                label={`System Prompt`}
                placeholder="Choisissez un system prompt"
                defaultValue={currentConversation?.system_prompt}
                {...register("system_prompt")}
                onBlur={handleSubmit(handleUpdateConversation)}

            />
            <div className='p-4 pb-3 shadow bg-base-100/50 border border-gray-200 my-4 rounded'>
                <div className='flex justify-between'>
                    <h4 className='uppercase'>Étapes</h4>
                    <div className="flex gap-2">
                        <button
                            className="btn btn-sm btn-success mt-4"
                            onClick={() => setStepsCount(stepsCount + 1)}
                        >
                            <PlusIcon className='h-4 text-white'/>
                        </button>
                        <button
                            disabled={stepsCount === 1}
                            className="btn btn-sm btn-error mt-4" onClick={() => {
                            setStepsCount(stepsCount - 1)
                            setValue("steps", currentConversation?.steps.slice(0, stepsCount - 1))
                        }}>
                            <MinusCircleIcon className='h-4 text-white'/>
                        </button>
                    </div>
                </div>
                {
                    Array.from(Array(stepsCount).keys()).map((i) => (
                            <div className='flex gap-3 items-end' key={`step-${i}`}>
                                <Textarea label={`Étape ${i + 1}`} placeholder={`Décris cette étape`}
                                          {...register("steps[" + i + "].content")}
                                          onBlur={handleSubmit(handleUpdateConversation)}
                                />
                            </div>
                        )
                    )
                }
            </div>


        </form>
    </>)
}

const VOICES_OPTIONS = [
    {value: "", label: "Default (FREE)"},
    {value: "2EiwWnXFnvU5JabPnv8n", label: "Clyde"},
    {value: "CYw3kZ02Hs0563khs1Fj", label: "Dave"},
    {value: "D38z5RcWu1voky8WS1ja", label: "Fin"},
    {value: "GBv7mTt0atIp3Br8iCZE", label: "Thomas"},
    {value: "IKne3meq5aSn9XLyUdCD", label: "Charlie"},
    {value: "XB0fDUnXU5powFXDhCwa", label: "Charlotte"},
    {value: "XrExE9yKIg1WjnnlVkGX", label: "Matilda"},
    {value: "jBpfuIE2acCO8z3wKNLl", label: "Gigi"},
    {value: "jsCqWAovK2LkecY7zXl4", label: "Freya"},
    {value: "oWAxZDx7w5VEj9dCyTzz", label: "Grace"},
    {value: "pMsXgVXv3BLzUgSXRplE", label: "Serena"}
]
