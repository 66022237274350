import React, {forwardRef, useState, useEffect, useRef} from 'react';

const Textarea = forwardRef(({
                                 label, name, placeholder, ...props
                             }, ref) => {

    const textareaRef = useRef(null);
    const [val, setVal] = useState(props.defaultValue);

    const handleChange = (e) => {
        setVal(e.target.value)
    }
    useEffect(() => {
        textareaRef.current.style.height = "0px";
        textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }, [val, textareaRef?.current])

    useEffect(() => {
        setVal(props.defaultValue)
    }, [props.defaultValue])


    return (
        <div className="form-control w-full">
            {label && (
                <label className="label">
                    <span className="label-text">{label}</span>
                </label>
            )}
            <textarea
                className="textarea textarea-bordered textarea-sm leading-5 h-auto"
                placeholder={placeholder}
                name={name}
                ref={(el) => {
                    textareaRef.current = el;
                    if (ref) {
                        ref(el);
                    }
                }}
                {...props}
                onChange={(e) => {
                    handleChange(e)
                    if (props.onChange) {
                        props.onChange(e)
                    }
                }}
            />
        </div>
    );
});


export default Textarea;