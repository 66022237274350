import React, { forwardRef } from "react";

const TextInput = forwardRef(({ label, name, placeholder, ...props }, ref) => {
  return (
    <div className="form-control w-full">
      {label && (
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
      )}
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        className="input input-bordered w-full input-sm"
        ref={ref}
        {...props}
      />
    </div>
  );
});

export default TextInput;
