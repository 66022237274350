import React, { forwardRef } from "react";

const Toggle = forwardRef(({ label, name, ...props }, ref) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer flex justify-start gap-2">
        <input type="checkbox" className="toggle toggle-sm" name={name} ref={ref} {...props} />
        {label && (
          <label className="label">
            <span className="label-text">{label}</span>
          </label>
        )}
      </label>
    </div>
  );
});

export default Toggle;

