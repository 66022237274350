import React from "react";
import {PageLayout} from "../components/page-layout";
import {Table} from "../components/table";

export const Admin = () => (
    <PageLayout>
        <div className='prose'>
            <h1>Admin</h1>
            <Table/>
        </div>

    </PageLayout>);
