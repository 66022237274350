import React from "react";
import {NavigationDrawer} from "./navigation-drawer";

export const PageLayout = ({children}) => {
    return (
        <div className="p-8 h-screen overflow-hidden bg-secondary">
            <div className='h-full flex rounded-xl overflow-hidden'>
                <NavigationDrawer/>
                <div className="bg-base-200 w-full h-full relative">
                    {children}
                </div>
            </div>
        </div>
    )
}