import React, {useState, useEffect, createContext} from "react";
import Navbar from "../components/navbar";
import {Select} from "../components/data-input/select";
import {useForm} from "react-hook-form";
import Textarea from "../components/data-input/textarea";
import CreateConversationForm from "../components/create-conversation-form";
import {XMarkIcon, MicrophoneIcon, SpeakerWaveIcon, CpuChipIcon} from "@heroicons/react/24/outline";
import Speech from "speak-tts";
import {useWhisper} from "@chengsokdara/use-whisper";
import {ConfigurationForm} from "../components/configuration-form";
import {textToSpeech} from "../utils/text-to-speech";
import {ClientScreen} from "../components/client-screen";
import {AnalysisTab} from "../components/analysis-tab";

export const ConversationContext = createContext(
    {
        currentConversation: null,
        updateConversation: () => {
        },
        setCurrentConversation: () => {
        },
        currentUserMessage: "",
        currentAssistantMessage: "",
        currentStep: '',
        lastAssistantMessage: "",
        startConversation: () => {
        },
        sendMsg: () => {

        }
    }
);

export const RecordingContext = createContext(
    {
        startRecording: () => {
        },
        stopRecording: () => {

        },
        pauseRecording: () => {

        },
        recording: false,
        speaking: false,
        transcribing: false,
        transcript: {},
        recordingDuration: 0,
    }
)

const Hello = (props) => {
    const [userMessage, setUserMessage] = useState('');
    const [isAiMessage, setIsAiMessage] = useState(false);
    const [assistantMessage, setAssistantMessage] = useState("");
    const [endToken, setEndToken] = useState(false);
    const [speechInstance, setSpeechInstance] = useState(null);
    const [currentSpokenPosition, setCurrentSpokenPosition] = useState(0);
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [isNewMessage, setIsNewMessage] = useState(false);


    useEffect(() => {
        try {
            const speech = new Speech(); // will throw an exception if not browser supported
            if (speech.hasBrowserSupport()) {
                // returns a boolean
                const speech = new Speech();
                speech.init({
                    volume: 0.5,
                    lang: "fr-FR",
                    voice: "Google français",
                    rate: 1.2,
                    pitch: 1,
                });
                setSpeechInstance(speech);
                console.debug("Votre navigateur supporte l'audio");
            }
        } catch (e) {
            console.debug("Erreur lors de l'instanciation de l'objet Speech", e);
            alert("Votre navigateur ne supporte pas l'audio");
        }
    }, []);

    useEffect(() => {
        fetch("/api/conversation")
            .then((response) => response.json())
            .then((data) => {
                setConversations(data);
                // set currentConversation
                const id = new URLSearchParams(window.location.search).get("id");
                const conversation = data.find(({id: _id}) => _id == id);
                setCurrentConversation(conversation);
            });
    }, []);

    const {recording, transcribing, speaking, transcript, startRecording, stopRecording, pauseRecording} = useWhisper({
        apiKey: "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", // YOUR_OPEN_AI_TOKEN
        streaming: true,
        timeSlice: 1_000,
        mode: "transcriptions",
        whisperConfig: {
            language: "fr",
            model: "whisper-1",
            temperature: 0,
        },
    });

    useEffect(() => {
        if (transcript.text) setUserMessage(transcript.text);
    }, [transcript.text]);
    const {register, handleSubmit, reset} = useForm();


    const updateConversation = (data) => {
        return fetch(`/api/conversation/update/${currentConversation.id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    }

    const createMessage = (role, content) => {
        return fetch(`/api/conversation/${currentConversation.id}/message`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                content,
                role,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // handle the response
                setCurrentConversation({
                    ...currentConversation,
                    messages: data,
                });
            });
    };

    useEffect(() => {
        if (endToken) {
            if (currentConversation.voice_id) {
                speakEleven(assistantMessage)
            }
            createMessage("assistant", assistantMessage).then(() => {
                setAssistantMessage("");
                setEndToken(false);
            });
        }
    }, [endToken]);

    useEffect(() => {
        if (!isNewMessage) return;
        const sse = new EventSource(`/api/chat/${currentConversation?.id}`);

        sse.onmessage = handleSSEMessage;

        return () => {
            sse.close();
        };
    }, [currentConversation?.id, isNewMessage]);


    useEffect(() => {
        if (!isAiMessage)
            return;

        const sse = new EventSource(`/api/chat/user_response/${currentConversation?.id}`);

        sse.onmessage = handleUserSSEMessage;

        return () => {
            sse.close();
        };

    }, [isAiMessage])

    const handleUserSSEMessage = (e) => {
        if (e.data == "[DONE]") {
            setIsAiMessage(false);
        } else {
            try {
                let txt = JSON.parse(e.data).choices[0].delta.content;
                if (txt) {
                    setUserMessage((prev) => (prev ? prev + txt : txt));
                }
            } catch (e) {
                console.log(e);
            }
        }
    }


    const handleSSEMessage = (e) => {
        if (e.data == "[DONE]") {
            setEndToken(true);
            setIsNewMessage(false);
        } else {
            try {
                let txt = JSON.parse(e.data).choices[0].delta.content;
                // console.debug("text", txt);
                // console timestamp in milliseconds
                const timestamp = new Date().getTime();
                if (txt) {
                    setAssistantMessage((prev) => (prev ? prev + txt : txt));
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (!speechInstance) return;
        // parse assistant message in sentences with punctuation at the end. Don't omit the punctuation at the end of the sentence
        const sentences = assistantMessage.split(/(?<=[\.\?\!])/);
        console.debug("sentences", sentences);
        // keep only the sentences after the current spoken position
        const sentencesToSpeak = sentences.slice(currentSpokenPosition);

        // get all the completed sentences (ending with a dot or any other punctuation)
        const completedSentences = sentencesToSpeak.filter((sentence) => sentence.trim().match(/[\.\?\!]/));
        if (!completedSentences.length) return;
        console.debug("completedSentences", completedSentences)

        setCurrentSpokenPosition((prev) => prev + completedSentences.length);

        if (!currentConversation.voice_id) {
            speechInstance.speak({
                text: completedSentences.join(" "),
            });
        }

    }, [assistantMessage]);


    const sendMsg = () => {
        createMessage("user", userMessage)
            .then(() => {
                setUserMessage("");
            })
            .then(() => {
                setIsNewMessage(true);
            });
    };


    useEffect(() => {
        // scroll container
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    }, [currentConversation?.messages]);

    const handleMicClick = () => {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const messagesEndRef = React.useRef(null);

    // get current tab from url
    const [currentTab, setCurrentTab] = useState(
        new URLSearchParams(window.location.search).get("tab") || "admin"
    )

    useEffect(() => {
        // update url with query params currentTab could be ? or & depending on the url
        // just add the currentTab to the url
        if (currentConversation?.id) {
            window.history.pushState({}, "", `?id=${currentConversation?.id}&tab=${currentTab}`);
        }
    }, [currentTab, currentConversation?.id])

    // Define a state variable to hold the audio URL
    const [audioURL, setAudioURL] = useState(null);
    const [isSpeakingLoading, setIsSpeakingLoading] = useState(false)

    // Define a function to fetch the audio data and set the URL state variable
    const speakEleven = async (text) => {
        if (!currentConversation.voice_id)
            return;
        setAudioURL(null);
        setIsSpeakingLoading(true)
        const data = await textToSpeech({
            text,
            voice_id: currentConversation.voice_id
        })
        // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
        const blob = new Blob([data], {type: 'audio/mpeg'});
        // Create a URL for the blob object
        const url = URL.createObjectURL(blob);
        // Set the audio URL state variable to the newly created URL
        setAudioURL(url);
        setIsSpeakingLoading(false)
    };

    return (
        <RecordingContext.Provider value={{
            startRecording,
            stopRecording,
            pauseRecording,
            recording,
            speaking,
            transcribing,
            transcript
        }}>
            <ConversationContext.Provider value={{
                currentConversation, updateConversation, setCurrentConversation,
                currentAssistantMessage: assistantMessage, currentUserMessage: userMessage,
                currentStep: Math.ceil(currentConversation?.messages.length / 2),
                lastAssistantMessage: currentConversation?.messages?.filter(({role}) => role === "assistant").pop()?.content,
                startConversation: () => {
                    setIsNewMessage(true)
                },
                sendMsg
            }}>
                <>
                    <div className="h-screen w-full flex flex-col">
                        <Navbar/>
                        <div className="bg-base-200 flex-1 flex w-screen overflow-hidden">
                            <div className="bg-base-200 w-1/3 h-full prose p-4 overflow-auto">
                                <form>
                                    <Select
                                        label="Conversations"
                                        placeholder="choisis une conversation"
                                        options={conversations.map(({id, title}) => {
                                            return {label: title, value: id};
                                        })}
                                        onChange={(e) => {
                                            // find conversation by id
                                            const conversation = conversations.find(({id}) => id == e.target.value);
                                            // set current conversation
                                            setCurrentConversation(conversation);
                                            // update url with query params id
                                            window.history.pushState({}, "", `?id=${e.target.value}`);
                                        }}
                                        disabled={!conversations.length}
                                        value={currentConversation?.id}
                                    />
                                </form>
                                {currentConversation && (
                                    <>
                                        <ConfigurationForm/>
                                    </>
                                )}
                                <hr/>
                                <CreateConversationForm/>
                            </div>
                            <div
                                className={`h-full flex flex-col flex-1 relative bg-base-300 overflow-hidden ${currentConversation ? '' : 'hidden'}`}>
                                <div
                                    className="tabs  h-[50px] tabs-boxed py-2  rounded-none  w-full  border-b border-b-gray-300">
                                    <a className={`tab ${currentTab === "admin" && "tab-active"}`}
                                       onClick={() => setCurrentTab("admin")}>Admin</a>
                                    <a className={`tab ${currentTab === "client" && "tab-active"}`}
                                       onClick={() => setCurrentTab("client")}>Client</a>
                                    <a className={`tab ${currentTab === "analysis" && "tab-active"}`}
                                       onClick={() => setCurrentTab("analysis")}>Analysis</a>
                                </div>
                                <ul className="steps text-xs h-[100px] bg-base-100 p-2">
                                    {currentConversation?.steps?.map((step, idx) => {
                                        const currentStep = Math.ceil(currentConversation?.messages.length / 2)
                                        return <li
                                            className={`step ${currentStep >= (idx + 1) && "step-primary"}`}>
                                            Étape {idx + 1}
                                        </li>
                                    })}
                                </ul>
                                <div className="flex-1 h-[calc(100%-150px)]">
                                    <div
                                        className={`${currentTab !== "admin" && 'hidden'} h-full overflow-hidden flex flex-col`}>
                                        <div className="p-4 h-fit flex-1 pb-12  pt-12 overflow-y-auto">
                                            {!currentConversation?.messages?.length && !assistantMessage && (
                                                <div className='text-center flex flex-col gap-2'>
                                                    <div className="text-center text-gray-500">Aucun message pour le
                                                        moment
                                                    </div>
                                                    <button
                                                        className={`btn m-auto btn-primary ${isNewMessage && 'disabled'}`}
                                                        onClick={() => {
                                                            setIsNewMessage(true)
                                                        }}>
                                                        Commencer entrevue
                                                    </button>
                                                </div>
                                            )}
                                            {currentConversation?.messages?.map(({role, content, id}, idx) => {
                                                return (
                                                    <div
                                                        className={`group relative pb-10 chat ${role === "user" ? "chat-start" : "chat-end"}`}
                                                        key={role + content + idx}
                                                    >
                                                        <div className="chat-image avatar placeholder">
                                                            <div
                                                                className="bg-neutral-focus text-neutral-content rounded-full w-8">
                                                                <span className="text-xs">K</span>
                                                            </div>
                                                        </div>
                                                        <div className="chat-header">{role}</div>
                                                        <div
                                                            className={`chat-bubble pr-10 ${role === "user" ? "chat-bubble-primary" : "chat-bubble-secondary"}`}
                                                        >
                                                            {content}
                                                            <button
                                                                className="btn btn-xs btn-circle btn-ghost absolute top-2 right-2"
                                                                onClick={() => {
                                                                    fetch(`/api/conversation/${currentConversation.id}/message/${id}`, {
                                                                        method: "DELETE",
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                        },
                                                                    })
                                                                        .then((response) => response.json())
                                                                        .then((data) => {
                                                                            // handle the response
                                                                            setCurrentConversation({
                                                                                ...currentConversation,
                                                                                messages: data,
                                                                            });
                                                                        });
                                                                }}
                                                            >
                                                                <XMarkIcon className="w-3 h-3 "/>
                                                            </button>
                                                        </div>
                                                        <div className="flex gap-2 bg-base-300 absolute bottom-0 mx-10">
                                                            <button
                                                                className="btn btn-outline btn-xs"
                                                                onClick={() => {
                                                                    if (currentConversation.voice_id) {
                                                                        speakEleven(content)
                                                                    } else {
                                                                        speechInstance.cancel();
                                                                        speechInstance.speak({
                                                                            text: content,
                                                                        });
                                                                    }

                                                                }}
                                                            >
                                                                <SpeakerWaveIcon className="w-4"/>
                                                                Ecouter
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {assistantMessage && (
                                                <div className="chat chat-end">
                                                    <div className="chat-image avatar placeholder">
                                                        <div
                                                            className="bg-neutral-focus text-neutral-content rounded-full w-8">
                                                            <span className="text-xs">K</span>
                                                        </div>
                                                    </div>
                                                    <div className="chat-header">assistant</div>
                                                    <div className="chat-bubble pr-10 chat-bubble-secondary"
                                                         id="assistant-message">
                                                        {assistantMessage}
                                                    </div>
                                                </div>
                                            )}
                                            <div ref={messagesEndRef}></div>
                                        </div>
                                        <div className="bg-white bg-opacity-0 relative bottom-0 w-full h-auto p-4">
                                            <Textarea
                                                name="chat"
                                                value={userMessage}
                                                disabled={isNewMessage}
                                                onChange={(e) => {
                                                    setUserMessage(e.target.value);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        sendMsg();
                                                    }
                                                }}
                                                rows={10}
                                            />
                                            <div className="flex gap-1 my-2 bg-base-200 p-2 rounded">
                                                <button className="btn btn-primary btn-sm" onClick={sendMsg}>
                                                    Envoyer
                                                </button>
                                                <button
                                                    disabled={transcribing}
                                                    className={`btn btn-secondary btn-sm ${recording || transcribing ? "" : "btn-ghost"}`}
                                                    onClick={handleMicClick}
                                                >
                                                    {recording || transcribing ? (
                                                        <span className="loading loading-ring loading-xs"></span>
                                                    ) : (
                                                        <MicrophoneIcon className="w-4"/>
                                                    )}
                                                    {recording ? "Arrêter" : "Enregister"}
                                                </button>
                                                <button className={'btn btn-ghost btn-sm'} disabled={isAiMessage}
                                                        onClick={() => {
                                                            setIsAiMessage(true)
                                                        }}>
                                                    <CpuChipIcon className='w-4'/>
                                                    IA utilisateur
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`${currentTab !== "client" && 'hidden'} h-full overflow-hidden flex flex-col p-8`}>
                                        <ClientScreen/>
                                    </div>
                                    <div
                                        className={`${currentTab !== "analysis" && 'hidden'} h-full overflow-hidden flex flex-col p-8`}>
                                        <AnalysisTab/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {audioURL && (
                        <audio autoPlay controls className='hidden'>
                            <source src={audioURL} type="audio/mpeg"/>
                        </audio>
                    )}
                </>
            </ConversationContext.Provider>
        </RecordingContext.Provider>
    );
};

const Configur = () => {
};

export default Hello;
